import React, { useEffect } from 'react';
import DownArrow from '../../assets/icons/arrowDown.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function About() {
    useEffect(() => {
        AOS.init({ duration: 500 });
    }, []);

    return (
        <div className="about" id="aboutSection">
            <div className="about__content container">
                <div className="row row__start">
                    <div className="about__heading heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                        about
                    </div>

                    <p className="about__info"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                    I'm Phoebe Lo, a full-stack developer and
                    UX/UI designer based in Vancouver. I create
                    projects under the notion of less is more and
                    my work reflects my addiction for minimal and
                    clean design as well as my desire to push
                    boundaries.
                    </p>
                </div>

                <div className="row row__end">
                    <div className="about__arrow"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                        <img src={DownArrow} alt="Down arrow" />
                    </div>

                    <p className="about__info about__info--end"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                    I've always had a natural curiosity for the
                    unknown and have always strived to educate
                    myself on how things work and why they do. I
                    love everything to do with development &
                    visual design as well as delivering
                    extraordinarily creative solutions across digital
                    platforms and beyond.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default About;