import React, { Component } from 'react';
import Intro from '../Intro/Intro';
import About from '../About/About';
import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';

class Home extends Component {
    render() {
        return (
            <>
                <Intro />
                <About />
                <Projects />
                <Contact />
            </>
        )
    }
}

export default Home;