import React, { useEffect } from 'react';
import DownArrow from '../../assets/icons/arrowDown.svg';
import Anime from 'react-anime';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Intro() {
    useEffect(() => {
        AOS.init({ duration: 500 });
    }, []);

    return (
        <div className="intro"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            >
            <div className="intro__content container">
                <div className="intro__info intro__role">
                    <h1 className="intro__header">Full-Stack</h1>
                </div>
                <div className="intro__info intro__developer">
                    <h1 className="intro__header">developer &</h1>
                </div>
                <div className="intro__info intro__designer">
                    <h1 className="intro__header">ux/ui designer</h1>
                </div>
                <div className="intro__info intro__location">
                    <h1 className="intro__header">based in</h1>
                </div>
                <div className="intro__info intro__vancouver">
                    <h1 className="intro__header">vancouver</h1>
                </div>

            <Anime 
                easing="easeOutElastic"
                loop={true}
                duration={1000}
                direction="alternate"
                delay={(el, index) => index * 240}
                translateY='1rem'
                >
                <div className="intro__arrow">
                    <img src={DownArrow} alt="Down arrow" />
                </div>
            </Anime>
            </div>
        </div>
    )
}

export default Intro;