import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <div className="header__content">
                    <div className="header__left">
                        <a href="/">
                            <h3 className="header__logo">Phoebe&nbsp;Lo</h3>
                        </a>
                    </div>

                    <div className="header__right">
                        <a href="/#aboutSection"
                            className="header__nav-item">
                            About
                        </a>
                        <a href="/#projectSection"
                            className="header__nav-item">
                            Projects
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}


export default Header;