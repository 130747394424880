import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import ProjectDetail from './components/Projects/ProjectsDetail';

function App(props) {
  return (
    <div className="App app__main">
      <div className="noise">
      <Header />
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/project" component={ProjectDetail}/>
          <Route path="/:title" render={(routeProps) => {
            return <ProjectDetail {...routeProps} />
          }}/>
        </Switch>
      <Footer />
      </div>
    </div>
  );
}

export default App;
