import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                Designed & Developed &#169; 2021 Phoebe Lo
            </div>
        )
    }
}

export default Footer;