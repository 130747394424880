import React, { useState, useEffect } from 'react';
import RightArrow from '../../assets/icons/arrowRight.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

 function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // AOS.init({ duration: 500 });
        // ProjectsData().then(data => {
        //     setProjects(data.projects)
        // })
        axios.get(process.env.PUBLIC_URL + "./projectData.json")
            .then((res) => {
                setProjects(res.data);
                // console.log(res.data)
            }).catch((err)=>
                console.log(err)
            )}, 
        []);

    return (
        <div className="project" id="projectSection">
            <div className="project__content container">
                <div className="row row__start">
                    <div className="project__heading heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                        projects
                    </div>

                    {/* <div className="project__list project__list--left">
                        {projects.map((item) => 
                            <div className="project__listItem"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                <div className="project__type">{item.category}</div>
                                <div className="project__title-container">
                                    <div className="project__title">{item.title}</div>
                                    <img class="rightarrow" src={RightArrow} alt="Right arrow" />
                                </div>
                            </div>
                        )}
                    </div> */}

                    {/* check project id, if project id 1-4 display on left column, else on right column*/}
                    {projects.map((item) => (
                        item.id < 5 ? (
                    <div className="project__list project__list--left">
                        <Link key={item.id} to={{ pathname: `/${item.title}`, state: item}}>
                            <div className="project__listItem"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                <div className="project__type">{item.category}</div>
                                <div className="project__title-container">
                                    <div className="project__title">{item.title}</div>
                                    <img class="rightarrow" src={RightArrow} alt="Right arrow" />
                                </div>
                            </div>
                        </Link>
                    </div>
                        )
                        : 
                        (
                            <div className="project__list project__list--right">
                                <Link key={item.id} to={{ pathname: `/${item.title}`, state: item}}>
                                    <div className="project__listItem"
                                    data-aos="fade-up"
                                    data-aos-delay="50"
                                    data-aos-duration="1000">
                                        <div className="project__type">{item.category}</div>
                                        <div className="project__title-container">
                                            <div className="project__title">{item.title}</div>
                                            <img class="rightarrow" src={RightArrow} alt="Right arrow" />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Projects;