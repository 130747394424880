import React from 'react';
import RightArrow from '../../assets/icons/arrowRight.svg';

function ProjectsDetail(props) {
    const item = props.location.state;

    return (
        <div className="project-detail">
            <div className="project-detail__content">
                <div className="project-detail__container--left">
                    <div className="project-detail__content-wrapper">
                        <div className="project-detail__content--top">
                            <h3 className="project-detail__category">{item.category}</h3>
                            <h1 className="project-detail__title">{item.title}</h1>
                        </div>
                        <div className="project-detail__content--bottom">
                            <div className="project-detail__list-item">
                                <h5 className="project-detail__header">Year</h5>
                                <p className="project-detail__year">{item.year}</p>
                            </div>
                            <div className="project-detail__list-item">
                                <h5 className="project-detail__header">Tool</h5>
                                <p className="project-detail__tool">{item.tool}</p>
                            </div>
                            <div className="project-detail__list-item">
                                <h5 className="project-detail__header">Role</h5>
                                <p className="project-detail__tool">{item.role}</p>
                            </div>
                            <div className="project-detail__list-item--link">
                                {item.github !== "" ? (
                                    <div className="project__listItem">
                                        <a href={item.github} target="_blank" className="project-detail__link" rel="noreferrer">View on GitHub</a>
                                        <img class="rightarrow--project" src={RightArrow} alt="Right arrow" />
                                    </div>
                                ): null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-detail__container--right">
                    <div className="project-detail__content-wrapper">
                        <div className="project-detail__image-container">
                            {item.image !== "" ? (
                                <img src={item.image} alt={item.title} className="project-detail__image"></img>
                            ): null}
                            {item.video !== "" ? (
                                <video className="project-detail__video" controls autoPlay muted loop>
                                    <source src={item.video} type="video/mp4"></source>
                                </video>
                            ): null}
                        </div>
                        <div className="project-detail__info-container">
                            <p className="project-detail__info-title">Overview</p>
                            <p className="project-detail__info">{item.overview}</p>
                        </div>
                        <div className="project-detail__info-container">
                            {item.background !== "" ? (
                                <div>
                                    <h3 className="project-detail__info-title">Background</h3>
                                    <p className="project-detail__info">{item.background}</p>
                                </div>
                            ): null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsDetail;