import React, { useEffect } from 'react';
import RightArrow from '../../assets/icons/arrowRight.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Contact() {
    useEffect(() => {
        AOS.init({ duration: 500 });
    }, []);
    return (
        <div className="contact">
            <div className="contact__content container">
                {/* <div className="contact__loop">
                    <div className="contact__loop-text">let’s build something creative and amazing together - let’s build something creative and amazing together - let’s build something creative and amazing together -</div>
                    <div className="contact__loop-text">let’s build something creative and amazing together - let’s build something creative and amazing together - let’s build something creative and amazing together -</div>
                    <div className="contact__loop-text">let’s build something creative and amazing together - let’s build something creative and amazing together - let’s build something creative and amazing together -</div>
                </div> */}

                <div className="row row__start">
                    <div className="contact__heading heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                        contact
                    </div>

                    <div className="contact__info">
                        <div className="contact__info--left">
                            <div className="contact__info-text"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                Ready in working together, grabbing a coffee or simply say hello?
                            </div>

                            <div className="contact__info-text"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                Great! Let's get in touch!
                            </div>
                        </div>
                    
                        <div className="contact__info--right">
                            <div className="contact__infoItem"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                <div className="contact__title">
                                    <a href="mailto:phoebeyclo@gmail.com">Email</a> 
                                </div>
                                <img class="rightarrow" src={RightArrow} alt="Right arrow" />
                            </div>

                            <div className="contact__infoItem"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                <div className="contact__title">
                                    <a href="https://www.linkedin.com/in/phoebe-lo-7960a41a4" target="_blank" rel="noreferrer">LinkedIn</a>
                                </div>
                                <img class="rightarrow" src={RightArrow} alt="Right arrow" />
                            </div>

                            <div className="contact__infoItem"
                            data-aos="fade-up"
                            data-aos-delay="50"
                            data-aos-duration="1000">
                                <div className="contact__title">
                                    <a href="https://github.com/PhoebeYCLo" target="_blank" rel="noreferrer">GitHub</a>
                                </div>
                                <img class="rightarrow" src={RightArrow} alt="Right arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Contact;